@font-face {
  font-display: swap;
  font-family: SuisseIntlMono;
  font-stretch: normal;
  font-style: normal;
  src: local(SuisseIntlMono-Regular),
    url("../fonts/SuisseIntlMono-Regular-WebS.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: SuisseIntlMono;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  src: local(SuisseIntlMono-Bold),
    url("../fonts/SuisseIntlMono-Bold-WebS.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: SuisseSign;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src: local(SuisseSign-Regular),
    url("../fonts/SuisseSign-Regular-WebS.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: SuisseSign;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  src: local(SuisseSign-SemiBold),
    url("../fonts/SuisseSign-SemiBold-WebS.woff2") format("woff2");
}
