@import "./tokens.css";
@import "./fonts.css";

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scrollbar-color: var(--color-grey) transparent;
}

body {
  background: var(--color-dark-blue);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  font-family: SuisseSign, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  min-height: 100vh;
}

header {
  align-items: center;
  background: var(--color-blue);
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  padding: 0.5em;
}

h1 {
  align-items: center;
  display: flex;
  font-family: SuisseIntlMono;
  font-size: inherit;
  font-weight: bold;
  gap: 0.5em;
  text-transform: uppercase;
}

a {
  color: inherit;
}

main {
  flex: 1;
}
