@import "../../node_modules/@factorial/fsk/css/variables.css";

html {
  --color-white: var(--fsk-color-white);
  --color-orange: var(--fsk-color-burning-orange);
  --color-dark-blue: #0c2951;
  --color-blue: var(--fsk-color-cerulean);
  --color-green: #00c58e;
  --color-grey: var(--fsk-color-grey);
}
